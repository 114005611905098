/* eslint-disable @typescript-eslint/ban-ts-comment */
import { injected } from 'wagmi/connectors';

import { Icon } from './icon';

export const okxId = 'okx';
export const okxName = 'OKX Wallet';

const deeplinkDAppUrl = globalThis.window ? globalThis.window.location.host + globalThis.window.location.pathname : '';
const deeplink = `okx://wallet/dapp/url?dappUrl=${deeplinkDAppUrl}`;
const urlWithDeeplink = `https://www.okx.com/download?deeplink=${deeplink}`;

const getOkxConnector = () => {
  return injected({
    target: () => ({
      id: okxId,
      name: okxName,
      // @ts-ignore
      provider: () => globalThis.window?.okxwallet || globalThis.window?.ethereum,
    }),
  });
};

const okxDetector = () => {
  // @ts-ignore
  return Boolean(globalThis.window?.okxwallet?.isOkxWallet) || Boolean(globalThis.window?.ethereum?.isOkxWallet);
};

export const Okx = () => ({
  walletName: okxName,
  walletId: okxId,
  type: injected.type,
  Icon,
  detector: okxDetector,
  deeplink: urlWithDeeplink,
  createConnectorFn: getOkxConnector(),
});
