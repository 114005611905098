import type { PropsWithChildren } from 'react';
import { wagmiConfig } from 'app/config/wallet';

import { ConnectModalProvider } from './contexts/connect-modal';
import { WagmiConnectStateProvider } from './contexts/wagmi-connect-state';
import { wallets } from './config';
import { ConnectModal } from './modals';

type Props = {
  chains: (typeof wagmiConfig)['chains'];
};

export const WithWagmiConnect = ({ chains, children }: PropsWithChildren<Props>) => {
  return (
    <WagmiConnectStateProvider chains={chains} wallets={wallets}>
      <ConnectModalProvider>
        <ConnectModal wallets={wallets} />
        {children}
      </ConnectModalProvider>
    </WagmiConnectStateProvider>
  );
};
