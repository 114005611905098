import { createSelector } from '@reduxjs/toolkit';
import { State } from 'app/store';

import { UserState } from './reducer';

const getUser = (state: State) => state.user;

const getProp =
  <T extends keyof UserState>(propKey: T) =>
  (state: State) =>
    state.user[propKey];

const selectUserCrowdsaleInfo = createSelector(
  getUser,
  ({ tier, totalSpend, referralReceived, cratBalance, bonusTokensReceived }) => ({
    tier,
    totalSpend,
    referralReceived,
    cratBalance,
    bonusTokensReceived,
  }),
);

const selectUserStableBalances = createSelector(getUser, ({ usdcBalance, usdtBalance }) => ({
  USDT: usdtBalance,
  USDC: usdcBalance,
}));

export const selectors = {
  getUser,
  getProp,
  selectUserCrowdsaleInfo,
  selectUserStableBalances,
};
