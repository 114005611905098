import { wagmiConfig } from 'app/config/wallet';
import { BigNumber } from 'bignumber.js';
import { modalModel } from 'entities/modal';
import { createAppAsyncThunk, ERC_20_ABI, logger, wagmiContracts } from 'shared';
import { Address } from 'viem';
import { getAccount, readContract, waitForTransactionReceipt, writeContract } from 'wagmi/actions';

import { actionTypes } from '../action-types';
import { ApproveArgs } from '../types';

export const approve = createAppAsyncThunk(
  actionTypes.APPROVE,
  async ({ stableCoinAddress, amount }: ApproveArgs, { rejectWithValue, dispatch }) => {
    const { address } = await getAccount(wagmiConfig);

    if (!address) {
      throw new Error('Empty provider');
    }

    dispatch(
      modalModel.actions.openModal({
        type: modalModel.ModalType.Approve,
        transition: 'fade',
      }),
    );

    const crowdsaleAllowance = await readContract(wagmiConfig, {
      abi: ERC_20_ABI,
      address: stableCoinAddress as Address,
      functionName: 'allowance',
      args: [address, wagmiContracts.contracts.Crowdsale.address],
    });

    const bigCrowdsaleAllowance = new BigNumber(crowdsaleAllowance.toString());

    if (bigCrowdsaleAllowance.isLessThan(amount)) {
      try {
        const approveTxHash = await writeContract(wagmiConfig, {
          abi: ERC_20_ABI,
          address: stableCoinAddress as Address,
          functionName: 'approve',
          args: [wagmiContracts.contracts.Crowdsale.address, BigInt(amount)],
        });

        const approveResult = await waitForTransactionReceipt(wagmiConfig, { hash: approveTxHash });

        if (approveResult.status !== 'success') {
          throw new Error('Failed to approve');
        }
      } catch (err) {
        dispatch(
          modalModel.actions.openModal({
            type: modalModel.ModalType.ApproveReject,
            transition: 'fade',
          }),
        );
        logger('approve', err);
        return rejectWithValue(err);
      }
    }

    return true;
  },
);
