export const initialOptions = ['Year', 'Coin Supply Minted', 'StakePool Reward', 'Supply Breakdown'];

export const tableHelper = [
  {
    title: 'Layer 1',
    values: [
      '2024-25',
      '10%',
      'Included',
      'Pre-ICO & ICO CrowdSale, Private Sale, IEO, VC & Angels Acquisition, CRAT Token «Swap> CRAT Native Coin, CratD2C-Pre Token «Swap> CRAT Native Coin, Community Trust Vesting Grant (CTVG), AirDrops, Validator/Delegator/Backers StakePool Reward, IP-portion Royalties, Marketing, Team Reward.',
    ],
  },
  {
    title: 'Layer 2',
    values: [
      '2026-27',
      '4%',
      'Included',
      'The supply breakdown is clarified in alignment with the precise requirements of the ecosystem at this particular mint period.',
    ],
  },
  {
    title: 'Layer 3', 
    values: [
      '2028-29',
      '8,6%',
      'Included',
      'The supply breakdown is clarified in alignment with the precise requirements of the ecosystem at this particular mint period.',
    ],
  },
  {
    title: 'Layer 4',
    values: [
      '2030-31',
      '15.8%',
      'Included',
      'The supply breakdown is clarified in alignment with the precise requirements of the ecosystem at this particular mint period.',
    ],
  },
  {
    title: 'Layer 5',
    values: [
      '2032-33',
      '12.6%',
      'Included',
      'The supply breakdown is clarified in alignment with the precise requirements of the ecosystem at this particular mint period.',
    ],
  },
  {
    title: 'Layer 6',
    values: [
      '2034-35',
      '19.5%',
      'Included',
      'The supply breakdown is clarified in alignment with the precise requirements of the ecosystem at this particular mint period.',
    ],
  },
  {
    title: 'Layer 7',
    values: [
      '2036-37',
      '11.8%',
      'Included',
      'The supply breakdown is clarified in alignment with the precise requirements of the ecosystem at this particular mint period.',
    ],
  },
  {
    title: 'Layer 8',
    values: [
      '2038-39',
      '17.7%',
      'Included',
      'The supply breakdown is clarified in alignment with the precise requirements of the ecosystem at this particular mint period.',
    ],
  },
];

export const gridConfig = {
  0: { xs: 1.5, md: 2 },
  1: { xs: 2.5, md: 2 },
  2: { xs: 3, md: 2 },
  3: { xs: 3, md: 4 },
};
