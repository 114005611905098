import { api, getAuthToken, RequestWithPagination } from 'shared';

import {
  AnswerQuizReq,
  GetLeaderboardRes,
  GetLeaderboardStatsRes,
  GetPurchaseRes,
  GetReferralsRes,
  GetTimerRes,
  UserQuizStatsRes,
} from './types';

const getTimer = () => {
  return api<GetTimerRes>({
    url: '/giveaway/timer/',
    method: 'GET',
  });
};

const getReferrals = () => {
  const token = getAuthToken();

  return api<GetReferralsRes>({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    url: '/giveaway/referrals/',
    method: 'GET',
  });
};

const getPurchasesInfo = () => {
  const token = getAuthToken();

  return api<GetPurchaseRes>({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    url: '/giveaway/purchase/',
    method: 'GET',
  });
};

const getLeaderboardStats = () => {
  const token = getAuthToken();

  return api<GetLeaderboardStatsRes>({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    url: '/giveaway/leader_board/statistics/',
    method: 'GET',
  });
};

const getLeaderboard = ({ pageNumber, pageSize }: RequestWithPagination) => {
  return api<GetLeaderboardRes>({
    url: `/giveaway/leader_board/?page_number=${pageNumber}&page_size=${pageSize}`,
    method: 'GET',
  });
};

const getUserQuizStats = () => {
  const token = getAuthToken();

  return api<UserQuizStatsRes>({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    url: '/giveaway/quiz/',
    method: 'GET',
  });
};

const answerQuiz = ({ data }: { data: AnswerQuizReq }) => {
  const token = getAuthToken();

  return api<UserQuizStatsRes>({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    url: '/giveaway/quiz/',
    method: 'POST',
    data,
  });
};

export const giveawayApi = {
  getTimer,
  getReferrals,
  getPurchasesInfo,
  getLeaderboardStats,
  getLeaderboard,
  getUserQuizStats,
  answerQuiz,
};

export * from './types';
