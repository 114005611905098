import { IconButton, styled, Typography } from '@mui/material';
import { useConnectModal } from 'app/providers';
import { COLOR_STROKE, COLOR_WH, FontWeights, Icons } from 'shared';

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(3),
  top: theme.spacing(3),
  width: 48,
  height: 48,
  borderRadius: '50%',
  border: '2px solid',
  borderColor: COLOR_STROKE,
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
  },
  svg: {
    color: COLOR_WH,
  },
})) as typeof IconButton;

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  fontSize: 24,
  fontWeight: FontWeights.SemiBold,
  [theme.breakpoints.up('sm')]: {
    textAlign: 'center',
    fontSize: 28,
  },
})) as typeof Typography;

export const Head = () => {
  const { close } = useConnectModal();

  return (
    <>
      <StyledTitle>Connect Wallet</StyledTitle>
      <CloseButton onClick={close}>
        <Icons.Close sx={{ width: 14, height: 14 }} />
      </CloseButton>
    </>
  );
};
