import { BreakpointsOptions, createTheme } from '@mui/material';

import {
  getMuiAccordion,
  getMuiAccordionDetails,
  getMuiAccordionPropsDefaultProps,
  getMuiAccordionSummary,
  getMuiAccordionSummaryPropsDefaultProps,
} from './Accordion';
import { getMuiBreadcrumbsDefaultProps, getMuiBreadcrumbsOverrides } from './Breadcrumbs';
import { getMuiButtonDefaultProps, getMuiButtonOverrides } from './Button';
import { getMuiCardOverrides } from './Card';
import { getMuiCheckbox, getMuiCheckboxDefaultProps } from './Checkbox';
import { colorThemes } from './colors';
import { getMuiContainerDefaultProps, getMuiContainerOverrides } from './Container';
import { getMuiCssBaselineDefaultProps, getMuiCssBaselineOverrides } from './CssBaseLine';
import { getMuiDialogOverrides } from './Dialog';
import { getMuiDividerOverrides } from './Divider';
import { getMuiGridDefaultProps, getMuiGridOverrides } from './Grid';
import { getMuiIconButtonDefaultProps, getMuiIconButtonOverrides } from './IconButton';
import { getMuiLinearProgressOverrides } from './LinearProgress';
import { getMuiMenuOverrides } from './Menu';
import { getMuiMenuItemDefaultProps, getMuiMenuItemOverrides } from './MenuItem';
import { getMuiPopoverDefaultProps, getMuiPopoverOverrides } from './Popover';
import { getMuiRadio, getMuiRadioDefaultProps } from './Radio';
import { getMuiSelectDefaultProps, getMuiSelectOverrides } from './Select';
import { getMuiSwitchDefaultProps, getMuiSwitchOverrides } from './Switch';
import { getMuiTabButtonOverrides, getMuiTabOverrides, getMuiTabsDefaultProps, getMuiTabsOverrides } from './Tabs';
import { getMuiTextFieldDefaultProps, getMuiTextFieldOverrides } from './TextField';
import { getMuiTooltipDefaultProps, getMuiTooltipOverrides } from './Tooltip';
import { getTypographyDefaultProps, getTypographyOverrides } from './Typography';

export const breakpointOptions: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 980,
    lg: 1340,
    xl: 1680,
  },
};

export const getTheme = (themeColors: typeof colorThemes) => {
  const themeBase = createTheme(
    {
      breakpoints: breakpointOptions,
    },
    {
      themeColors,
    },
  );

  return createTheme(themeBase, {
    components: {
      MuiDialog: {
        styleOverrides: getMuiDialogOverrides(themeBase),
      },
      MuiCssBaseline: {
        styleOverrides: getMuiCssBaselineOverrides(themeBase),
        defaultProps: getMuiCssBaselineDefaultProps(),
      },
      MuiGrid: {
        defaultProps: getMuiGridDefaultProps(),
        styleOverrides: getMuiGridOverrides(),
      },
      MuiTypography: {
        styleOverrides: getTypographyOverrides(themeBase),
        defaultProps: getTypographyDefaultProps(),
      },
      MuiButton: {
        defaultProps: getMuiButtonDefaultProps(),
        styleOverrides: getMuiButtonOverrides(themeBase),
      },
      MuiRadio: {
        styleOverrides: getMuiRadio(themeBase),
        defaultProps: getMuiRadioDefaultProps(),
      },
      MuiCheckbox: {
        defaultProps: getMuiCheckboxDefaultProps(),
        styleOverrides: getMuiCheckbox(themeBase),
      },
      MuiIconButton: {
        styleOverrides: getMuiIconButtonOverrides(themeBase),
        defaultProps: getMuiIconButtonDefaultProps(),
      },
      MuiTextField: {
        styleOverrides: getMuiTextFieldOverrides(themeBase),
        defaultProps: getMuiTextFieldDefaultProps(),
      },
      MuiContainer: {
        styleOverrides: getMuiContainerOverrides(themeBase),
        defaultProps: getMuiContainerDefaultProps(),
      },
      MuiCard: {
        styleOverrides: getMuiCardOverrides(themeBase),
      },
      MuiLinearProgress: {
        styleOverrides: getMuiLinearProgressOverrides(themeBase),
      },
      MuiDivider: {
        styleOverrides: getMuiDividerOverrides(themeBase),
      },
      MuiSelect: {
        styleOverrides: getMuiSelectOverrides(themeBase),
        defaultProps: getMuiSelectDefaultProps(),
      },
      MuiMenu: {
        styleOverrides: getMuiMenuOverrides(themeBase),
      },
      MuiMenuItem: {
        styleOverrides: getMuiMenuItemOverrides(themeBase),
        defaultProps: getMuiMenuItemDefaultProps(),
      },
      MuiPopover: {
        styleOverrides: getMuiPopoverOverrides(themeBase),
        defaultProps: getMuiPopoverDefaultProps(),
      },
      MuiAccordion: {
        defaultProps: getMuiAccordionPropsDefaultProps(),
        styleOverrides: getMuiAccordion(themeBase),
      },
      MuiAccordionDetails: {
        styleOverrides: getMuiAccordionDetails(themeBase),
      },
      MuiAccordionSummary: {
        styleOverrides: getMuiAccordionSummary(themeBase),
        defaultProps: getMuiAccordionSummaryPropsDefaultProps(),
      },
      MuiTabs: {
        styleOverrides: getMuiTabsOverrides(themeBase),
        defaultProps: getMuiTabsDefaultProps(),
      },
      MuiTab: {
        styleOverrides: getMuiTabOverrides(themeBase),
        defaultProps: { disableRipple: true },
      },
      MuiTabScrollButton: {
        styleOverrides: getMuiTabButtonOverrides(themeBase),
      },
      MuiSwitch: {
        defaultProps: getMuiSwitchDefaultProps(),
        styleOverrides: getMuiSwitchOverrides(themeBase),
      },
      MuiBreadcrumbs: {
        defaultProps: getMuiBreadcrumbsDefaultProps(),
        styleOverrides: getMuiBreadcrumbsOverrides(themeBase),
      },
      MuiTooltip: {
        defaultProps: getMuiTooltipDefaultProps(),
        styleOverrides: getMuiTooltipOverrides(themeBase),
      },
    },
  });
};
