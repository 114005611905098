import { Popover } from '@mui/material';

import { LogoutMenu } from './logout-menu';

type Props = {
  isOpen: boolean;
  anchor: HTMLElement | null;
  onClose: () => void;
};

export const LogoutPopover = ({ anchor, isOpen, onClose }: Props) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          padding: '32px',
          maxWidth: 350,
          width: '100%',
          mt: 1,
        },
      }}
    >
      <LogoutMenu onClose={onClose} />
    </Popover>
  );
};
