/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getDevice } from 'shared';
import { coinbaseWallet } from 'wagmi/connectors';

import { Icon } from './icon';

export const coinbaseId = 'coinbase';
export const coinbaseName = 'Coinbase';

const device = getDevice();

export const getCoinbaseConnector = () => {
  return coinbaseWallet({ appName: globalThis.window?.location?.hostname, preference: 'eoaOnly' });
};

const detector = () => {
  /** @note handles mobile app  */
  if (device.isMobile || device.isTablet) {
    return true;
  }

  // @ts-ignore
  return Boolean(
    globalThis.window?.ethereum?.isCoinbaseWallet || globalThis.window?.ethereum?.providerMap?.has?.('CoinbaseWallet'),
  );
};

export const Coinbase = () => ({
  walletName: coinbaseName,
  walletId: coinbaseId,
  type: coinbaseWallet.type,
  Icon,
  shouldUseDetector: false,
  detector,
  createConnectorFn: getCoinbaseConnector(),
});
