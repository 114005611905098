import { wagmiConfig } from 'app/config/wallet';
import { crowdsaleModel } from 'entities/crowdsale';
import { modalModel } from 'entities/modal';
import { userModel } from 'entities/user';
import { createAppAsyncThunk, getChainExplorerUrl, logger, toDecimals, wagmiContracts } from 'shared';
import { Address } from 'viem';
import { writeContract } from 'wagmi/actions';

import { actionTypes } from '../action-types';
import { BuyTokensArgs } from '../types';

import { approve } from './approve';
import { getCrowdsaleData } from './getCrowdsaleData';

const TRANSACTION_TIMEOUT_SEC = 60 * 1000;

export const buyTokens = createAppAsyncThunk(
  actionTypes.BUY_TOKENS,
  async (
    { fatherAddress, stablesAmount, stableCoinAddress, price, chain }: BuyTokensArgs,
    { rejectWithValue, dispatch },
  ) => {
    let transactionTimerId: ReturnType<typeof setTimeout> | null = null;

    try {
      dispatch(
        crowdsaleModel.actions.updateBuyData({
          fatherAddress,
          stablesAmount,
          stableCoinAddress,
          price,
        }),
      );

      const blockExplorerUrl = getChainExplorerUrl(chain);

      const tokenAmountWithDecimals = toDecimals(stablesAmount, 18);

      try {
        await dispatch(approve({ stableCoinAddress, amount: tokenAmountWithDecimals }));
      } catch (err) {
        return rejectWithValue(err);
      }

      dispatch(
        modalModel.actions.openModal({
          type: modalModel.ModalType.Send,
          transition: 'fade',
        }),
      );

      let isTimedOut = false;

      transactionTimerId = setTimeout(() => {
        isTimedOut = true;
        dispatch(
          modalModel.actions.openModal({
            type: modalModel.ModalType.SendTimeout,
            transition: 'fade',
          }),
        );
      }, TRANSACTION_TIMEOUT_SEC);

      const crowdsaleContract = wagmiContracts.contracts.Crowdsale;

      const refundAmount = await crowdsaleContract.read.calculateReferralRefundAmount([
        stableCoinAddress as Address,
        fatherAddress as Address,
        BigInt(tokenAmountWithDecimals),
      ]);

      const buyTransactionHash = await writeContract(wagmiConfig, {
        abi: crowdsaleContract.abi,
        address: crowdsaleContract.address,
        functionName: 'buyCratTokens',
        args: [stableCoinAddress as Address, BigInt(tokenAmountWithDecimals), fatherAddress as Address, refundAmount],
      });

      if (isTimedOut) {
        return rejectWithValue('Transaction processing took too long');
      }

      await dispatch(getCrowdsaleData());
      await dispatch(userModel.thunks.getUserInfo());

      dispatch(
        modalModel.actions.openModal({
          type: modalModel.ModalType.SendSuccess,
          data: {
            transactionLink: `${blockExplorerUrl}/tx/${buyTransactionHash}`,
          },
          transition: 'fade',
        }),
      );

      return buyTransactionHash;
    } catch (err) {
      dispatch(
        modalModel.actions.openModal({
          type: modalModel.ModalType.SendReject,
          transition: 'fade',
        }),
      );

      logger('buyTokens', err);
      return rejectWithValue(err);
    } finally {
      if (transactionTimerId) {
        clearTimeout(transactionTimerId);
      }
    }
  },
);
