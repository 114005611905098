/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import type { Chain } from 'viem';
import type { Config } from 'wagmi';

import type { WalletAdapterData } from '../../../types';

import { connectToAdapter } from './connect-to-adapter';

export const connectEagerly = async (
  config: Config,
  adapters: WalletAdapterData[],
  supportedChains: readonly [Chain, ...Chain[]],
) => {
  for (const adapter of adapters) {
    if (!adapter.detector?.()) {
      continue;
    }

    try {
      const res = await connectToAdapter(config, adapter.createConnectorFn, supportedChains);

      return res;
    } catch (e) {
      return null;
    }
  }

  return null;
};
