import { alpha, Link, Typography } from '@mui/material';
import { COLOR_ACCENT } from 'shared';
import { CalculatorBlock } from 'widgets';

import { ComparisonTable, Intro, Mechanics } from './ui';

export const Staking = () => {
  return (
    <>
      <Intro />
      <Mechanics />
      <ComparisonTable />
      <CalculatorBlock
        bottom={
          <Typography variant="h6" sx={{ mt: 5, gridColumn: { xs: 'span 12', md: '5 / 13' } }}>
            In summary, the staking mechanism of CratD2C Decentralized Autonomous Smart Chain offers a comprehensive and
            inclusive approach to network participation. It enables Validators, Delegators, and Backers to contribute to
            the platform&apos;s security, consensus, and governance, while earning rewards and potential royalty
            returns. This mechanism underlines CratD2C&apos;s commitment to creating an engaged, empowered, and
            sustainable ecosystem.
            <br />
            <br />
            <Link
              href="https://staking-testnet.cratd2csmartchain.io"
              referrerPolicy="no-referrer"
              rel="noreferrer"
              target="_blank"
              className="accent medium"
              underline="hover"
              sx={{ textDecorationColor: alpha(COLOR_ACCENT, 0.5) }}
            >
              Start staking now!
            </Link>
          </Typography>
        }
      />
    </>
  );
};
