import { useCallback } from 'react';
import { crowdsaleModel } from 'entities/crowdsale';
import { useAppDispatch, useShallowSelector } from 'shared';
import { useAccount } from 'wagmi';

export const useRetryBuy = () => {
  const dispatch = useAppDispatch();
  const buyData = useShallowSelector(crowdsaleModel.selectors.getProp('currentBuyData'));

  const { chain } = useAccount();

  const retryBuy = useCallback(() => {
    if (buyData && chain) {
      dispatch(crowdsaleModel.thunks.buyTokens({ ...buyData, chain }));
    }
  }, [buyData, dispatch, chain]);

  return retryBuy;
};
