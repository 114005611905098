import { UAParser } from 'ua-parser-js';

const parser = new UAParser();

export const getDevice = () => {
  const os = parser.getOS();
  const isIOS = os.name === 'iOS';
  const isAndroid = os.name === 'Android';

  const device = parser.getDevice();
  const isIPad = device.model === 'iPad';
  const isMobile = device.type === 'mobile';
  const isTablet = device.type === 'tablet';

  return {
    isIOS,
    isAndroid,
    isIPad,
    isMobile,
    isTablet,
  };
};
