import { createStore } from 'mipd';

import { ADAPTERS } from './constants';
import { getWalletsData } from './utils';

export const providersStore = createStore();

export const wallets = getWalletsData(ADAPTERS, {
  walletconnectProjectId: process.env.REACT_APP_WC_PROJECT_ID ?? '',
  providersStore,
});
