import { wagmiConfig } from 'app/config/wallet';
import { createAppAsyncThunk, logger } from 'shared';
import { wagmiContracts } from 'shared/config';
import { getAccount } from 'wagmi/actions';

import { actionTypes } from '../action-types';

export const getUserInfo = createAppAsyncThunk(actionTypes.GET_USER_INFO, async (_, { rejectWithValue }) => {
  try {
    const { address } = await getAccount(wagmiConfig);

    if (!address) {
      throw new Error('Empty provider');
    }

    const userInfoPromise = wagmiContracts.contracts.Crowdsale.read.userInfo([address]);
    const cratBalancePromise = wagmiContracts.contracts.Crat.read.balanceOf([address]);
    const usdtBalancePromise = wagmiContracts.contracts.USDT.read.balanceOf([address]);
    const usdcBalancePromise = wagmiContracts.contracts.USDC.read.balanceOf([address]);

    const [userInfo, cratBalance, usdtBalance, usdcBalance] = await Promise.all([
      userInfoPromise,
      cratBalancePromise,
      usdtBalancePromise,
      usdcBalancePromise,
    ]);

    const thunkPayload = {
      userInfo: {
        totalSpend: userInfo[0].toString(),
        tier: userInfo[1].toString(),
        bonusTokensReceived: userInfo[2].toString(),
        referralReceived: userInfo[3].toString(),
        referralFather: userInfo[4].toString(),
      },
      cratBalance: cratBalance.toString(),
      usdtBalance: usdtBalance.toString(),
      usdcBalance: usdcBalance.toString(),
    };

    return thunkPayload;
  } catch (err) {
    logger('crowdsaleData', err);
    return rejectWithValue(err);
  }
});
