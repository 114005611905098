import { forwardRef } from 'react';
import { IconButton } from '@mui/material';
import { useWagmiAuth } from 'app/providers';
import { COLOR_STROKE, Icons } from 'shared';

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const AUTH_ICON_SX = {
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translate(50%, -50%)',
};

export const MobileButton = forwardRef<HTMLButtonElement, Props>(({ onClick }, ref) => {
  const { state } = useWagmiAuth();

  return (
    <IconButton id="connect-button" ref={ref} onClick={onClick} color="secondary" sx={{ position: 'relative' }}>
      {!state.isAuthorized ? (
        <Icons.CancelCircleRed sx={AUTH_ICON_SX} />
      ) : (
        <Icons.ConfirmCircleGreen sx={AUTH_ICON_SX} />
      )}
      <Icons.Wallet sx={{ color: COLOR_STROKE }} />
    </IconButton>
  );
});
