import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { WagmiConnectStateContext } from './context';
import { useAutoConnect } from './hooks';
import type { WagmiConnectState } from './types';

type Props = Pick<WagmiConnectState, 'chains' | 'wallets'>;

export const WagmiConnectStateProvider = ({ chains, wallets, children }: PropsWithChildren<Props>) => {
  const [isReconnecting, setIsReconnecting] = useState(true);

  const handleReconnectComplete = useCallback(() => setIsReconnecting(false), []);

  const contextValue = useMemo(() => ({ chains, wallets, isReconnecting }), [chains, wallets, isReconnecting]);

  useAutoConnect(wallets, chains, handleReconnectComplete);

  return <WagmiConnectStateContext.Provider value={contextValue}>{children}</WagmiConnectStateContext.Provider>;
};
