import { useCallback } from 'react';
import type { Chain } from 'viem';
import { useConfig } from 'wagmi';

import type { WalletAdapterData } from '../../../types';
import { connectEagerly } from '../utils';

export const useEagerConnect = (wallets: WalletAdapterData[], chains: readonly [Chain, ...Chain[]]) => {
  const config = useConfig();

  const eagerConnect = useCallback(() => {
    const autoConnectOnlyAdapters = wallets.filter(({ autoConnectOnly }) => autoConnectOnly);

    return connectEagerly(config, autoConnectOnlyAdapters, chains);
  }, [wallets, chains, config]);

  return { eagerConnect };
};
