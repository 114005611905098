import { useMemo } from 'react';
import { alpha, Dialog, styled } from '@mui/material';
import { BORDER_RADIUS_2XL, COLOR_STROKE, getDevice } from 'shared';
import { useConfig } from 'wagmi';

import { RECONNECT_KEY } from '../../constants';
import { WalletButton } from '../../features';
import { useConnectModal } from '../../hooks';
import type { WalletAdapterData } from '../../types';

import { Head } from './components';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    [theme.breakpoints.up('xs')]: {
      paddingInline: theme.spacing(1.5),
    },
  },
  '& [role=dialog]': {
    background: alpha('#141414', 0.35),
    backdropFilter: 'blur(80px)',
    borderRadius: BORDER_RADIUS_2XL,
    border: `1px solid ${COLOR_STROKE}`,
    padding: theme.spacing(3),
    position: 'relative',
    maxWidth: 520,
    width: '100%',
    marginInline: 'initial',
    [theme.breakpoints.down(664)]: {
      maxWidth: `520px !important`,
    },
  },
})) as typeof Dialog;

const List = styled('ul')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  marginTop: theme.spacing(3),
  gap: theme.spacing(1.5, 2),
  li: { display: 'contents' },
  '& > li:nth-child(2n-1):nth-last-of-type(1) > *': {
    gridColumn: 'span 2 / span 2',
  },
}));

type Props = {
  wallets: WalletAdapterData[];
};

export const ConnectModal = ({ wallets }: Props) => {
  const { isOpen, close } = useConnectModal();
  const config = useConfig();

  const device = useMemo(getDevice, []);

  const handleConnectSuccess = (walletId: string) => () => {
    config.storage?.setItem(RECONNECT_KEY, walletId);
    close();
  };

  return (
    <StyledDialog open={isOpen} onClose={close} scroll="body">
      <Head />
      <List>
        {wallets.map((wallet) => {
          if ((wallet.isDesktopOnly && device.isMobile) || (wallet.isMobileOnly && !device.isMobile)) {
            return null;
          }

          return (
            <li key={wallet.walletId}>
              <WalletButton
                {...wallet}
                onConnect={handleConnectSuccess(wallet.walletId)}
                connector={wallet.createConnectorFn}
              />
            </li>
          );
        })}
      </List>
    </StyledDialog>
  );
};
