import { useEffect } from 'react';
import { Divider, Stack, styled } from '@mui/material';
import { useWagmiAuth } from 'app/providers';
import { userModel } from 'entities/user';
import { useAppDispatch } from 'shared';

import { Faq, GiveawayOverview, Leaderboard, Purchase, Quiz, ReferralSection, Steps } from './ui';

const DesktopDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
export const Giveaway = () => {
  const dispatch = useAppDispatch();
  const { state: authState } = useWagmiAuth();

  useEffect(() => {
    if (authState.address) {
      dispatch(userModel.thunks.getUserInfo());
    }
  }, [dispatch, authState.address]);

  return (
    <Stack gap={{ xs: 5, md: 9 }} mb={{ xs: 5, md: 15.75 }}>
      <GiveawayOverview />
      <Steps />
      <Purchase />
      <DesktopDivider flexItem />
      <Quiz />
      <DesktopDivider flexItem />
      {authState.address && (
        <>
          <ReferralSection />
          <DesktopDivider flexItem />
        </>
      )}
      <Leaderboard />
      <Faq />
    </Stack>
  );
};
