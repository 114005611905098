/* eslint-disable @typescript-eslint/ban-ts-comment */
import { capitalize, isString } from 'lodash';
import { ProviderRpcError, SwitchChainError, TransactionRejectedRpcError, UserRejectedRequestError } from 'viem';

export const extractErrorMessage = (error: unknown): string => {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof SwitchChainError) {
    switch (error.code) {
      case 4902:
        return 'Unable to switch the chain. You may need to switch it manually';
      default:
        return 'Unable to switch the chain';
    }
  }

  if (
    error instanceof UserRejectedRequestError ||
    error instanceof TransactionRejectedRpcError ||
    (error instanceof Error &&
      (error.cause instanceof UserRejectedRequestError || error.cause instanceof TransactionRejectedRpcError))
  ) {
    // @ts-ignore
    if (error.cause.code === 4200) {
      return 'Unable to switch the chain. You may need to switch it manually';
    }

    return 'User rejected the transaction';
  }

  if (error instanceof ProviderRpcError) {
    return error.shortMessage;
  }

  if (error instanceof Error) {
    switch (error.message) {
      case 'unknown reason':
        return 'Transaction Failed';
      default:
        return error.message;
    }
  }

  if (error && typeof error === 'object') {
    if ('message' in error) {
      return error.message as string;
    }

    if ('detail' in error) {
      return error.detail as string;
    }

    const errorKeys = Object.keys(error);

    if (errorKeys.length > 0) {
      // @ts-expect-error Types for the key
      const firstValue = error?.[errorKeys[0]];

      if (Array.isArray(firstValue) && firstValue.length > 0 && isString(firstValue[0]))
        return capitalize(firstValue[0]);

      if (isString(firstValue)) return capitalize(firstValue);
    }
  }

  return 'Something went wrong';
};
