import { Typography } from '@mui/material';
import { OptionsEnum } from 'features/calculator';
import { Advantages, Intro, PageParagraph } from 'shared';
import { CalculatorBlock } from 'widgets';

import { BOTTOM_TEXT, intro, list } from './constants';

export const Delegator = () => {
  return (
    <>
      <Intro
        mainTitle="Delegator"
        paragraphSubtitle={intro.subtitle}
        paragraph={intro.paragraph}
        paragraphProps={{ letterSpacing: '-0.3px !important' }}
      />
      <Advantages
        list={list}
        listTitle={
          <Typography variant="h4" className="summary">
            Here are five crucial advantages of being a Delegator in this ecosystem:
          </Typography>
        }
        headTitle="Advantages"
      />
      <PageParagraph sx={{ mb: { xs: 6, md: 11 } }} pProps={{ sx: { letterSpacing: '-0.4px !important' } }}>
        These advantages highlight the significance of Delegators in the CratD2C ecosystem, offering them a way to
        contribute to the network&#39;s success while earning rewards, all with the flexibility to adjust their
        participation and influence the ecosystem&#39;s governance indirectly.
      </PageParagraph>
      <CalculatorBlock activeOption={OptionsEnum.Delegator} bottom={BOTTOM_TEXT} />
    </>
  );
};
