/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Backdrop, ComponentsOverrides, ComponentsProps, Fade, Theme } from '@mui/material';

import { BOX_SHADOW_1 } from '../common';

export const getMuiPopoverOverrides = (theme: Theme): ComponentsOverrides['MuiPopover'] => ({
  paper: {
    boxShadow: BOX_SHADOW_1,
    borderRadius: '20px',
    margin: '8px',
  },
});

export const getMuiPopoverDefaultProps = (): ComponentsProps['MuiPopover'] => ({
  TransitionComponent: Fade,
  transitionDuration: 650,
  slots: {
    // @ts-ignore
    backdrop: Backdrop,
  },
  slotProps: {
    // @ts-ignore
    backdrop: {
      timeout: 650,
    },
  },
});
