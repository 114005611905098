import { appRoutes, BASE_URL, CertikLogo, HackenLogoLight, Icons, links } from 'shared';

// TODO: refactor this shit configs
export const headerNavLinks = [
  {
    title: 'Smart-Chain',
    path: appRoutes.smartchain.root.path,
    subpaths: [
      {
        title: 'Smart-Chain',
        path: appRoutes.smartchain.root.path,
      },
      {
        title: 'Blockchain Features',
        path: appRoutes.smartchain.children?.blockchainFeatures.root.path,
      },
      {
        title: 'Smart Chain Scope',
        path: appRoutes.smartchain.children?.scope.root.path,
      },
      { title: 'Latency & TPS', path: appRoutes.smartchain.children?.latency.root.path },
      { title: 'Staking', path: appRoutes.smartchain.children?.staking.root.path },
      { title: 'Validator', path: appRoutes.smartchain.children?.validator.root.path },
      { title: 'Delegator', path: appRoutes.smartchain.children?.delegator.root.path },
      { title: 'LiteBacker', path: appRoutes.smartchain.children?.litebacker.root.path },
      { title: 'TurboBacker', path: appRoutes.smartchain.children?.turbobacker.root.path },
      { title: 'CTVG: Build on CratD2C (Receive Grant)', path: appRoutes.smartchain.children?.ctvg.root.path },
      { title: 'Comparative Analysis', path: appRoutes.smartchain.children?.comparativeAnalysis.root.path },
      { title: 'Wallet', path: appRoutes.smartchain.children?.wallet.root.path },
      { title: 'Explorer', path: appRoutes.smartchain.children?.explorer.root.path },
    ],
  },
  {
    title: 'Use Cases',
    path: appRoutes.useCases.root.path,
    subpaths: [
      { title: 'Use Cases', path: appRoutes.useCases.root.path },
      { title: 'Our Resources', path: appRoutes.resources.root.path },
    ],
  },
  {
    title: 'e-Market Portals',
    path: appRoutes.marketPortals.root.path,
  },
  {
    title: 'Tokenomics',
    path: appRoutes.tokenomics.root.path,
    subpaths: [
      { title: 'Tokenomics', path: appRoutes.tokenomics.root.path },
      { title: 'Distribution & Vesting', path: appRoutes.tokenomics.children?.distribution.root.path },
      { title: '8-Layer Zig-Zag Supply Mechanism', path: appRoutes.tokenomics.children?.biennialLayer.root.path },
      { title: 'Earning with CratD2C', path: appRoutes.tokenomics.children?.earning.root.path },
    ],
  },
  {
    title: 'Intellectual Property',
    path: appRoutes.intellectualProperty.root.path,
    subpaths: [
      { title: 'Intellectual Property', path: appRoutes.intellectualProperty.root.path },
      { title: 'IP-Certification', path: appRoutes.intellectualProperty.children?.ipCertification.root.path },
      { title: 'Ecosystem Valuation', path: appRoutes.intellectualProperty.children?.ecosystemValuation.root.path },
      { title: 'IP-Portions Issuance', path: appRoutes.intellectualProperty.children?.ipPortionsIssuance.root.path },
      { title: 'IP-Portion Royalties', path: appRoutes.intellectualProperty.children?.ipPortionRoyalties.root.path },
      { title: 'IP-Portion Seed-Rounds', path: appRoutes.intellectualProperty.children?.ipPortionSeedRounds.root.path },
    ],
  },
  {
    title: 'Connect',
    path: appRoutes.connect.root.path,
    subpaths: [
      { title: 'Project Team', path: appRoutes.connect.children?.projectTeam.root.path },
      { title: 'Ambassadorial System', path: appRoutes.connect.children?.ambassadorialSystem.root.path },
      { title: 'Eco-Community', path: appRoutes.connect.children?.ecoCommunity.root.path },
      { title: 'VC / Angel', path: appRoutes.connect.children?.vcAngel.root.path },
      { title: 'Upcoming Events', path: appRoutes.connect.children?.upcomingEvents.root.path },
      { title: 'Press Release', path: appRoutes.connect.children?.pressReleases.root.path },
      { title: 'Career with CratD2C', path: appRoutes.connect.children?.career.root.path },
      { title: 'Bug Bounty', path: appRoutes.connect.children?.bugBounty.root.path },
    ],
  },
  {
    title: 'WIN 200K Giveaway',
    path: appRoutes.giveaway.root.path,
  },
];

export const footerNavLinks = [
  {
    id: 1,
    links: [
      ...(headerNavLinks[0].subpaths ? headerNavLinks[0].subpaths.slice(0, 12) : []),
      { title: 'Explorer', path: 'https://explorer-testnet.cratd2csmartchain.io', isExternal: true },
      { title: 'Faucet', path: 'https://faucet-testnet.cratd2csmartchain.io', isExternal: true },
    ],
  },
  {
    id: 2,
    links: headerNavLinks[3].subpaths,
  },
  {
    id: 3,
    links: headerNavLinks[4].subpaths,
  },
  {
    id: 4,
    links: [
      { title: 'Connect', path: appRoutes.connect.children?.projectTeam.root.path },
      ...(headerNavLinks[5].subpaths || []),
    ],
  },
  {
    id: 5,
    links: [
      { title: 'General', path: appRoutes.home.root.path },
      { title: 'Use Cases', path: appRoutes.useCases.root.path },
      { title: 'Our Resources', path: appRoutes.resources.root.path },
      { title: 'e-Market Portals', path: appRoutes.marketPortals.root.path },
      { title: 'WhitePaper', path: `${BASE_URL}/whitepaper/`, isExternal: true },
      {
        title: 'Pitch deck',
        path: 'https://www.canva.com/design/DAGAEYHzt3M/9zQhIOQQqk9ivot1HyfvQg/edit?utm_content=DAGAEYHzt3M&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton',
        isExternal: true,
      },
      { title: 'WIN 200K GIVEAWAY', path: appRoutes.giveaway.root.path },
      { title: 'T&C', path: appRoutes.termsAndConditions.root.path },
      { title: 'Privacy Policy', path: appRoutes.privacyPolicy.root.path },
      {
        title: 'Dev Updates',
        path: 'https://cratd2csmartchain.gitbook.io/cratd2c-blockchain-testnet-and-mainnet-transition',
        isExternal: true,
      },
      {
        title: 'Airdrops',
        path: 'https://cratd2cairdrop.io/',
        isExternal: true,
      },
    ],
  },
];

export const socials = [
  {
    Icon: Icons.Telegram,
    url: links.tgRoom,
    title: 'Community Chat',
    description: 'Ask general questions and chat with the worldwide community on Telegram.',
  },
  {
    Icon: Icons.Discord,
    url: links.discord,
    title: 'Developer Chat',
    description: 'Have technical questions about CratD2C tools? Ask a developer on the Community Discord.',
  },
  {
    Icon: Icons.Twitter,
    url: links.twitter,
    title: 'Twitter',
    description: 'Follow @CratD2C to get the latest news and updates from across the ecosystem.',
  },
  {
    Icon: Icons.Linkedin,
    url: links.linkedin,
    title: 'Linked in',
    description: 'cratd2csmartchain',
  },
  {
    Icon: Icons.Youtube,
    url: links.youtube,
    title: 'Youtube',
    description: 'cratd2csmartchain',
  },
  {
    Icon: Icons.Facebook,
    url: links.facebook,
    title: 'Facebook',
    description: 'cratd2csmartchain',
  },
];

export const certificates = [
  { title: 'Certik', img: CertikLogo, url: 'https://skynet.certik.com/projects/cratd2c' },
  {
    title: 'Hacken',
    img: HackenLogoLight,
    url: 'https://hacken.io/audits/cratd2c-decentralized-autonomous-smart-chain/',
  },
];
