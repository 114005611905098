import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

export const Icon = forwardRef<SVGSVGElement, ComponentProps<'svg'>>((props, ref) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
      <rect width="48" height="48" rx="24" fill="#3B99FC" />
      <path
        d="M16.3227 19.1135C20.5631 14.9622 27.4373 14.9622 31.6776 19.1135L32.1874 19.6135C32.2377 19.6622 32.2777 19.7207 32.305 19.7852C32.3324 19.8498 32.3465 19.9191 32.3465 19.9892C32.3465 20.0593 32.3324 20.1287 32.305 20.1933C32.2777 20.2578 32.2377 20.3162 32.1874 20.365L30.4417 22.0743C30.3902 22.1241 30.3215 22.152 30.2499 22.152C30.1783 22.152 30.1095 22.1241 30.0581 22.0743L29.3559 21.3865C26.3976 18.4906 21.602 18.4906 18.6444 21.3865L17.8922 22.1231C17.8408 22.1729 17.772 22.2007 17.7004 22.2007C17.6288 22.2007 17.56 22.1729 17.5086 22.1231L15.7623 20.4132C15.712 20.3644 15.6721 20.306 15.6448 20.2415C15.6176 20.177 15.6035 20.1077 15.6035 20.0377C15.6035 19.9677 15.6176 19.8984 15.6448 19.8339C15.6721 19.7694 15.712 19.711 15.7623 19.6622L16.322 19.1135H16.3227ZM35.288 22.6484L36.8412 24.1698C36.8915 24.2186 36.9314 24.277 36.9587 24.3414C36.9859 24.4059 37 24.4753 37 24.5453C37 24.6153 36.9859 24.6846 36.9587 24.7491C36.9314 24.8136 36.8915 24.872 36.8412 24.9207L29.8357 31.78C29.7329 31.8796 29.5953 31.9353 29.4521 31.9353C29.3089 31.9353 29.1714 31.8796 29.0685 31.78L24.0961 26.9122C24.0704 26.8873 24.036 26.8734 24.0002 26.8734C23.9644 26.8734 23.93 26.8873 23.9043 26.9122L18.9325 31.78C18.8296 31.8798 18.6919 31.9356 18.5485 31.9356C18.4052 31.9356 18.2675 31.8798 18.1646 31.78L11.1591 24.9207C11.1088 24.872 11.0687 24.8135 11.0414 24.749C11.0141 24.6844 11 24.6151 11 24.5449C11 24.4748 11.0141 24.4055 11.0414 24.3409C11.0687 24.2764 11.1088 24.2179 11.1591 24.1692L12.713 22.6478C12.8158 22.5481 12.9534 22.4924 13.0966 22.4924C13.2398 22.4924 13.3773 22.5481 13.4802 22.6478L18.4526 27.5162C18.5053 27.5682 18.5911 27.5682 18.6444 27.5162L23.6162 22.6484C23.7191 22.5488 23.8567 22.4931 23.9998 22.4931C24.143 22.4931 24.2806 22.5488 24.3834 22.6484L29.3559 27.5162C29.4092 27.5682 29.495 27.5682 29.5477 27.5162L34.5195 22.6484C34.6224 22.5486 34.7601 22.4928 34.9034 22.4928C35.0468 22.4928 35.1845 22.5486 35.2873 22.6484H35.288Z"
        fill="white"
      />
    </svg>
  );
});
