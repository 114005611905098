import {
  Coinbase,
  coinbaseId,
  Exodus,
  exodusId,
  MetaMask,
  metaMaskId,
  Okx,
  okxId,
  Rainbow,
  rainbowId,
  WalletConnect,
  wcId,
} from '../adapters';
import type { WalletAdapter } from '../types';

export const ADAPTERS: Record<string, WalletAdapter> = {
  [metaMaskId]: MetaMask,
  [okxId]: Okx,
  [coinbaseId]: Coinbase,
  [exodusId]: Exodus,
  [rainbowId]: Rainbow,
  [wcId]: WalletConnect,
};
